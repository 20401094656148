<template>
  <div class="landlordInfo-detail">
    <app-header v-if="title" :title="title" :isShowBack="true"></app-header>
    <div class="landlordInfo_top">
      <el-button type="primary" @click="changeAdministrator()"
        >更换管理员</el-button
      >
    </div>
    <app-detail :opt="opt"></app-detail>

    <el-dialog title="更换管理员" :visible.sync="changeAdmVisible" width="32%">
      <div style="text-align: center; padding: 0 40px">
        <img
          style="width: 200px"
          :src="qrCodeUrl"
        />

        <div style="padding-bottom: 15px">
          请<span style="color: #f79f1b; font-weight: 600">新管理员</span
          >打开微信--扫一扫，扫描上方二维码，按照页面提示填写信息提交认证，即可更换管理员。
        </div>
        <div style="padding-bottom: 40px; color: #30bac1; font-weight: 600">
          <span style="cursor: pointer" @click="downloadQRcode()">
            <i class="el-icon-download"></i>
            <span>下载二维码</span>
          </span>
        </div>
      </div>
      <div style="text-align: center">
        <span slot="footer" class="dialog-footer">
          <el-button @click="changeAdmVisible = false">关闭</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      title: "房东信息",
      changeAdmVisible: false,
      qrCodeUrl: "",
      opt: {
        id: "",
        form: [
          {
            label: "商户信息",
            type: "TitleName",
          },
          {
            label: "商户编号",
            key: "orgCode",
            isCol50: true,
          },
          {
            label: "商户名称",
            key: "orgName",
            isCol50: true,
          },
          {
            label: "商户类型",
            key: "orgType",
            isCol50: true,
            type: "select",
            opt: {
              list: [
                {
                  value: 2,
                  label: "个人",
                },
                {
                  value: 1,
                  label: "企业/单位",
                },
              ],
            },
          },
          {
            label: "房源（个）",
            key: "houseCount",
            isCol50: true,
          },
          {
            label: "企业/单位名称",
            key: "entName",
            isCol50: true,
            filters: {
              key: "orgType",
              value: 1,
            },
          },
          {
            label: "统一社会信用代码",
            key: "socialCreditCode",
            isCol50: true,
            filters: {
              key: "orgType",
              value: 1,
            },
          },
          {
            label: "是否法人认证",
            key: "isAuthorizer",
            type: "select",
            isCol50: true,
            filters: {
              key: "orgType",
              value: 1,
            },
            opt: {
              list: [
                {
                  value: 0,
                  label: "是",
                },
                {
                  value: 1,
                  label: "否",
                },
              ],
            },
          },
          {
            label: "管理员信息",
            type: "TitleName",
          },
          {
            label: "管理员姓名",
            key: "realname",
            isCol50: true,
          },
          {
            label: "性别",
            key: "gender",
            isCol50: true,
          },
          {
            label: "出生日期",
            key: "birthday",
            isCol50: true,
          },
          {
            label: "身份证号",
            key: "idcardno",
            isCol50: true,
          },
          {
            label: "手机号码",
            key: "phonenum",
            isCol50: true,
          },
          {
            label: "紧急联系人",
            key: "emergencyContract",
            isCol50: true,
          },
          {
            label: "紧急联系人电话",
            key: "emergencyPhoneNum",
            isCol50: true,
          },
          {
            label: "推荐人",
            key: "referrerName",
            isCol50: true,
          },
          {
            label: "推荐人电话",
            key: "referrerPhone",
            isCol50: true,
          },
          {
            label: "现居住城市",
            key: "currentLivingCity",
            isCol50: true,
          },
          {
            label: "详细地址",
            key: "addressStr",
          },
        ],
        get(opt) {
          let dto = {};
          _this.post("user-service/landlord/getInfo", dto).then((data) => {
            if (data.gender == 1) {
              data.gender = "男";
            } else if (data.gender == 2) {
              data.gender = "女";
            } else {
              data.gender = "未知";
            }
            data.addressStr = data.address ? data.address : "无";
            data.currentLivingCity =
              (data.province ? data.province : "") +
              (data.city ? data.city : "") +
              (data.district ? data.district : "");
            if (data.orgInfoBriefInfo != null) {
              data.orgCode = data.orgInfoBriefInfo.orgCode;
              data.orgName = data.orgInfoBriefInfo.orgName;
              data.orgType = data.orgInfoBriefInfo.orgType;
              data.houseCount = data.orgInfoBriefInfo.houseCount || 0;
              data.entName = data.orgInfoBriefInfo.entName;
              data.socialCreditCode = data.orgInfoBriefInfo.socialCreditCode;
              data.isAuthorizer =
                data.orgInfoBriefInfo.isAuthorizer == null
                  ? 0
                  : data.orgInfoBriefInfo.isAuthorizer;
            }
            opt.cb(data);
          });
        },
      },
    };
  },
  created() {
    this.id = this.$route.params.id || "";
    console.log("apartment-detail created!!");
  },
  methods: {
    changeAdministrator() {
      this.changeAdmVisible = true;
      this.post("/tenant-service/utils/generate-administration-qrCode",{}).then(res => {
        this.qrCodeUrl = "data:image/png;base64," + res.result;
      })
    },
    downloadQRcode() {
      var image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function() {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL('image/png') // 得到图片的base64编码数据

        var a = document.createElement('a') // 生成一个a元素
        var event = new MouseEvent('click') // 创建一个单击事件
        a.download = '更换管理员二维码' || 'photo' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      image.src = this.qrCodeUrl
    }
  },
};
</script>
<style lang="scss" scoped>
.landlordInfo_top {
  text-align: right;
  padding-bottom: 10px;
  padding-right: 80px;
  border-bottom: solid 1px #eeeeee;
}
</style>

