var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "landlordInfo-detail" },
    [
      _vm.title
        ? _c("app-header", { attrs: { title: _vm.title, isShowBack: true } })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "landlordInfo_top" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.changeAdministrator()
                }
              }
            },
            [_vm._v("更换管理员")]
          )
        ],
        1
      ),
      _c("app-detail", { attrs: { opt: _vm.opt } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "更换管理员",
            visible: _vm.changeAdmVisible,
            width: "32%"
          },
          on: {
            "update:visible": function($event) {
              _vm.changeAdmVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "center", padding: "0 40px" } },
            [
              _c("img", {
                staticStyle: { width: "200px" },
                attrs: { src: _vm.qrCodeUrl }
              }),
              _c("div", { staticStyle: { "padding-bottom": "15px" } }, [
                _vm._v("\n        请"),
                _c(
                  "span",
                  { staticStyle: { color: "#f79f1b", "font-weight": "600" } },
                  [_vm._v("新管理员")]
                ),
                _vm._v(
                  "打开微信--扫一扫，扫描上方二维码，按照页面提示填写信息提交认证，即可更换管理员。\n      "
                )
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    "padding-bottom": "40px",
                    color: "#30bac1",
                    "font-weight": "600"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          _vm.downloadQRcode()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-download" }),
                      _c("span", [_vm._v("下载二维码")])
                    ]
                  )
                ]
              )
            ]
          ),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.changeAdmVisible = false
                      }
                    }
                  },
                  [_vm._v("关闭")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }